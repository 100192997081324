<template>
 <TableStyleWrapper>
  <TableWrapper class="table-responsive">
   <a-table
    :columns="productTableColumns"
    :dataSource="productTableData"
    :loading="loadingProducts"
    :pagination="{
          defaultPageSize: state.product.pageSize,
          total: count,
          current: currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${count} items`,
        }"
    @change="paginationChange"
   />
  </TableWrapper>
 </TableStyleWrapper>
</template>
<script>
import {TableStyleWrapper} from '../style';
import {TableWrapper} from '../../styled';
import {computed, defineComponent} from 'vue';
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import moment from 'moment';
import {useAbility} from "@casl/vue";

const productTableColumns = [
 {
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
 },
 {
  title: 'Naam',
  dataIndex: 'name',
  key: 'name',
 },
 {
  title: 'Type',
  dataIndex: 'type',
  key: 'type',
 },
 {
  title: 'Aangemaakt op',
  dataIndex: 'createTimestamp',
  key: 'createTimestamp',
 },
 {
  title: 'Status',
  dataIndex: 'active',
  key: 'active',
 },
 {
  title: 'Actions',
  dataIndex: 'action',
  key: 'action',
  width: '90px',
 },
];
const ProductListTable = defineComponent({
 name: 'ProductListTable',
 components: {TableStyleWrapper, TableWrapper},
 setup() {
  const router = useRouter();
  const {state, dispatch} = useStore();
  const {matched} = useRoute();
  const {path} = matched[1];
  const products = computed(() => state.product.products);
  const currentPage = computed(() => state.product.currentPage);
  const count = computed(() => state.product.count);
  const loadingProducts = computed(() => state.product.loadingProducts);
  const productTypes = computed(() => state.product.types);
  const {can} = useAbility();
  const handleDelete = (id) => {
   dispatch('deleteProduct', id);
  };
  const handleEdit = (id) => {
   router.push(`${path}/${id}`);
  };

  const getTypeName = (type) => {
   const index = productTypes.value.findIndex((t) => {
    if (t.key === type) {
     return true;
    }
   });

   if (index > -1) {
    return productTypes.value[index].value;
   }

   return '';
  }

  const productTableData = computed(() =>
   products.value.map((product) => {
    const {id, name, imageFileURL, createTimestamp, active, type} = product;

    return {
     key: id,
     id: id,
     name: (
      <div class="user-info">
       <figure>
        {imageFileURL && imageFileURL !== '' ? (
         <img style={{width: '40px'}} src={`${imageFileURL}`} alt=""/>
        ) : (
         <img style={{width: '40px'}} src={require('@/static/img/users/1.png')} alt=""/>
        )}
       </figure>
       <figcaption>
        <sdHeading class="user-name" as="h6">
         {name}
        </sdHeading>
       </figcaption>
      </div>
     ),
     type: <span>{getTypeName(type)}</span>,
     active: (<span className="status-text" style={{
      color: 'white',
      backgroundColor: active ? '#0CAB7C' : '#E30D0F'
     }}>{active ? 'Actief' : 'Inactief'}</span>),
     createTimestamp: <span>{moment(createTimestamp).format('DD-MM-YYYY')}</span>,
     action: (
      <div className="table-actions">
       {can('view', 'product') ? (
        <sdButton onClick={() => handleEdit(id)} className="btn-icon" to="#" type="default" shape="circle">
         <sdFeatherIcons type="edit" size="16"/>
        </sdButton>
       ) : ('')}
       {can('delete', 'product') ? (
        <a-popconfirm
         title="Weet je het zeker? U kunt dit niet meer terugdraaien!"
         ok-text="Ja"
         cancel-text="Nee"
         placement="leftTop"
         onConfirm={() => handleDelete(id)}
        >
         <sdButton className="btn-icon" type="default" to="#" shape="circle">
          <sdFeatherIcons type="trash-2" size="16"/>
         </sdButton>
        </a-popconfirm>
       ) : ('')}
      </div>
     ),
    };
   }),
  );

  const rowSelection = {
   getCheckboxProps: (record) => ({
    disabled: record.name === 'Disabled product', // Column configuration not to be checked
    name: record.name,
   }),
  };

  const paginationChange = async (event) => {
   await dispatch('setCurrentPageProduct', event.current);
   await dispatch('getProducts', {page: event.current, pageSize: state.product.pageSize});
  }

  return {
   productTableColumns,
   productTableData,
   rowSelection,
   loadingProducts,
   paginationChange,
   count,
   currentPage,
   state,
  };
 },
});

export default ProductListTable;
</script>
