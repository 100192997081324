<template>
 <CardToolbox>
  <sdPageHeader title="Producten">
   <template #subTitle>
    <span class="title-counter">{{ count }} product{{ count > 0 && count < 2 ? '' : 'en' }}</span>
    <AutoCompleteStyled placeholder="Zoeken op naam..." style="width:100%">
     <a-input v-model:value="search" type="input">
      <template #suffix>
       <sdFeatherIcons type="search"/>
      </template>
     </a-input>
    </AutoCompleteStyled>
    <!--        <sdPopover placement="bottomLeft" action="click">-->
    <!--          <template v-slot:content>-->
    <!--            <a-form ref="formRef" :model="filters" layout="vertical" @finish="applyFilters">-->
    <!--              <a-row :gutter="16">-->
    <!--                <a-col :span="24">-->
    <!--                  <a-form-item label="Organisatie" name="organizationID" style="margin-bottom: 5px">-->
    <!--                    <a-select v-model:value="filters.organizationID" size="large" class="sDash_fullwidth-select"-->
    <!--                              :allowClear="true" mode="multiple">-->
    <!--                      <a-select-option name="organizationID" v-for="(item,index) in organizationFilters" :key="index"-->
    <!--                                       :value="item.id">-->
    <!--                        {{ item.name }}-->
    <!--                      </a-select-option>-->
    <!--                    </a-select>-->
    <!--                  </a-form-item>-->
    <!--                </a-col>-->
    <!--                <a-col :span="24">-->
    <!--                  <a-form-item label="Rol" name="roleID" style="margin-bottom: 10px">-->
    <!--                    <a-select v-model:value="filters.roleID" size="large" name="roleID" class="sDash_fullwidth-select"-->
    <!--                              :allowClear="true" mode="multiple">-->
    <!--                      <a-select-option v-for="(item,index) in roleFilters" :key="index" :value="item.id">-->
    <!--                        {{ item.name }}-->
    <!--                      </a-select-option>-->
    <!--                    </a-select>-->
    <!--                  </a-form-item>-->
    <!--                </a-col>-->
    <!--                <a-col :span="24">-->
    <!--                  <a-form-item label="Status" name="roleID" style="margin-bottom: 10px">-->
    <!--                    <a-select v-model:value="filters.status" size="large" name="roleID" class="sDash_fullwidth-select"-->
    <!--                              :allowClear="true" mode="multiple">-->
    <!--                      <a-select-option v-for="(item,index) in UserStatusOptions" :key="index" :value="item.value">-->
    <!--                        {{ item.key }}-->
    <!--                      </a-select-option>-->
    <!--                    </a-select>-->
    <!--                  </a-form-item>-->
    <!--                </a-col>-->
    <!--                <a-col span="24">-->
    <!--                  <a-button size="large" class="btn-signin  mr-2" type="primary" html-type="submit"-->
    <!--                            style="line-height: 1;text-align: center;">-->
    <!--                    <sdFeatherIcons type="filter" size="14" class="mr-1" style="line-height: 1;text-align: center"/>-->
    <!--                    <span>Filter</span></a-button>-->
    <!--                  <a-button size="large" class="btn-signin" type="secondary" @click.prevent="resetFilters"-->
    <!--                            style="line-height: 1;text-align: center;" :disabled="!hasFilters">-->
    <!--                    <span>Reset</span></a-button>-->
    <!--                </a-col>-->
    <!--              </a-row>-->
    <!--            </a-form>-->
    <!--          </template>-->
    <!--          <sdButton class="ml-5" size="small" type="white" shape="rounded" style="height: 46px">-->
    <!--            <a-badge v-if="hasFilters" dot style="margin-left:0" class="mr-2">-->
    <!--              <sdFeatherIcons type="filter" size="14"/>-->
    <!--            </a-badge>-->
    <!--            <sdFeatherIcons v-else type="filter" size="14"/>-->
    <!--            Filters-->
    <!--          </sdButton>-->
    <!--        </sdPopover>-->
   </template>
   <template #buttons>
    <ProductAdd v-if="$can('create', 'product')"/>
   </template>
  </sdPageHeader>
 </CardToolbox>

 <Main>
  <a-row :gutter="15">
   <a-col :md="24">
    <ProductListTable/>
   </a-col>
  </a-row>
 </Main>
</template>
<script>
import ProductListTable from './component/ProductTable';
import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {AutoCompleteStyled, CardToolbox, Main} from '../styled';
import ProductAdd from "./ProductAdd";
import locale from "@/static/locale/nl.json";

const ProductList = defineComponent({
 name: 'ProductList',
 components: {ProductAdd, Main, CardToolbox, ProductListTable, AutoCompleteStyled},
 setup() {
  const {state, dispatch} = useStore();
  const searchData = computed(() => state.headerSearchData);
  const count = computed(() => state.product.count)
  const filters = computed(() => state.product.filters);
  const selectedRowKeys = ref(0);
  const selectedRows = ref(0);
  const search = ref(null);
  let debounce = null;

  watch(search, (query) => {
   clearTimeout(debounce)
   debounce = setTimeout(async () => {
    const searchValues = {
     q: query,
     pageSize: state.organization.pageSize,
     page: 1,
    };
    await dispatch('setCurrentPageProduct', 1);
    await dispatch('getProducts', searchValues);
   }, 600);
  });

  onMounted(() => {
   dispatch('fetchAvailableProductTypes');
   dispatch('getSuppliers');
   dispatch('getProducts', {page: state.product.currentPage, pageSize: state.product.pageSize});
  });

  return {
   searchData,
   selectedRowKeys,
   selectedRows,
   count,
   locale,
   search,
   filters,
  };
 },
});

export default ProductList;
</script>
