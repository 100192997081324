<template>
 <sdDrawer
  ref="ProductAddRef"
  :form="form"
  btnText="Toevoegen"
  submitBtnText="Opslaan"
  title="Product aanmaken"
  type="submit"
 >
  <FormValidationWrap>
   <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" @finish="CreateProduct">
    <a-row :gutter="16">
     <a-col :span="24">
      <a-form-item label="Naam" name="name">
       <a-input v-model:value="form.name" placeholder=""/>
      </a-form-item>
     </a-col>
    </a-row>
    <a-row :gutter="16">
     <a-col :span="24">
      <a-form-item label="Type" name="type">
       <a-select v-model:value="form.type" :allowClear="true" class="sDash_fullwidth-select"
                 size="large">
        <a-select-option v-for="(item,index) in productTypes" :key="index"
                         :value="item.key" name="type">
         {{ item.value }}
        </a-select-option>
       </a-select>
      </a-form-item>
     </a-col>
    </a-row>
    <div
     :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
    >
     <a-button :disabled="loadingCreateProduct" :loading="loadingCreateProduct" class="btn-signin" html-type="submit"
               size="large"
               type="primary"> Opslaan
     </a-button>
    </div>
   </a-form>
  </FormValidationWrap>
 </sdDrawer>
</template>
<script>
import {computed, defineComponent, reactive, ref} from 'vue';
import {FormValidationWrap} from "../styled";
import {useStore} from "vuex";

const UserAdd = defineComponent({
 name: 'ProductAdd',
 components: {
  FormValidationWrap,
 },
 setup() {
  const {state, dispatch} = useStore();
  const ProductAddRef = ref();
  const formRef = ref();
  const suppliers = computed(() => state.supplier.suppliers);
  const loadingCreateProduct = computed(() => state.product.loadingCreateProduct);
  const productTypes = computed(() => state.product.types);
  const form = reactive({
   name: null,
   type: null,
   energyUsageLimitationType: 'NONE',
  });
  const rules = {
   name: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   type: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };
  const closeDrawer = () => {
   ProductAddRef.value.onClose();
   formRef.value.resetFields();
   dispatch('getProducts');
  }
  const CreateProduct = () => {
   dispatch('createProduct', {value: form, close: closeDrawer});
  };

  return {
   form,
   rules,
   CreateProduct,
   ProductAddRef,
   formRef,
   suppliers,
   loadingCreateProduct,
   productTypes,
  };
 },
});

export default UserAdd;
</script>
